import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Typography, Button, Form, Select } from 'antd';
import { getIncomers } from 'react-flow-renderer/nocss';
import ETLModale from './ETL/ETLModale';
import TaskCRUDModale from './CRUD/TaskCRUDModale';
import EmailModale from './Email/EmailModale';
import { mapInputs, mapOutputs } from '../../utils';
import { useErrorMessage } from '../../../../utils/errorMessage';

const { Item } = Form;
const { Option } = Select;
const { Title, Paragraph } = Typography;

const WFLTaskForm = ({
  selectedElement,
  elements,
  outputs,
  setOutputs,
  operations,
  setOperations,
  collections
}) => {
  const { message } = useErrorMessage();
  const [isETLModalVisible, setIsETLModalVisible] = useState(false);
  const [isCRUDModalVisible, setIsCRUDModalVisible] = useState(false);
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [sourceElements, setSourceElements] = useState([]);
  const [typeOfTask, setTypeOfTask] = useState(
    selectedElement.data.taskType || ''
  );

  const changeTypeOfTask = (value) => {
    if (typeOfTask !== value) setOperations([]);
    setTypeOfTask(value);
  };

  useEffect(() => {
    try {
      const incomers = getIncomers(selectedElement, elements);
      setSourceElements(incomers);
    } catch (e) {
      setIsCRUDModalVisible(false);
      setOperations([]);
      message('load_fail');
    }
  }, []);

  return (
    <>
      <Title level={5}>Action</Title>

      <Item label="Type d'action" name="taskType" rules={[{ required: true }]}>
        <Select
          showSearch
          onChange={changeTypeOfTask}
          value={typeOfTask}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          filterSort={(optionA, optionB) =>
            optionA.children
              .toLowerCase()
              .localeCompare(optionB.children.toLowerCase())
          }
        >
          <Option key="bi" value="bi">
            Transformation de données
          </Option>
          <Option key="crud" value="crud">
            CRUD
          </Option>
          <Option key="email" value="email">
            Envoi d&apos;email
          </Option>
        </Select>
      </Item>

      {typeOfTask === 'bi' && (
        <>
          <Row className="drawer-action-row">
            <Col span={12}>
              <Title level={5} style={{ textAlign: 'center' }}>
                Inputs
              </Title>
              <Paragraph>{mapInputs(sourceElements)}</Paragraph>
            </Col>
            <Col span={12}>
              <Title level={5} style={{ textAlign: 'center' }}>
                Outputs
              </Title>
              <Paragraph>
                <Paragraph>{mapOutputs(outputs)}</Paragraph>
              </Paragraph>
            </Col>
          </Row>
          <Row>
            <Button
              type="primary"
              block
              onClick={() => setIsETLModalVisible(true)}
              disabled={typeOfTask === ''}
            >
              Manipuler les données
            </Button>
            <ETLModale
              isModalVisible={isETLModalVisible}
              setIsModalVisible={setIsETLModalVisible}
              handleOk={() => setIsETLModalVisible(false)}
              handleCancel={() => setIsETLModalVisible(false)}
              sourceElements={sourceElements}
              operations={operations}
              outputs={outputs}
              setOperations={setOperations}
              setOutputs={setOutputs}
            />
          </Row>
        </>
      )}
      {typeOfTask === 'crud' && (
        <Row className="drawer-action-row">
          <Row style={{ width: '100%' }}>
            <Col span={12}>
              <Title level={5} style={{ textAlign: 'center' }}>
                Inputs
              </Title>
              <Paragraph>{mapInputs(sourceElements)}</Paragraph>
            </Col>
            <Col span={12}>
              <Title level={5} style={{ textAlign: 'center' }}>
                Outputs
              </Title>
              <Paragraph>
                <Paragraph>{mapOutputs(outputs)}</Paragraph>
              </Paragraph>
            </Col>
          </Row>
          <Button
            type="primary"
            block
            onClick={() => setIsCRUDModalVisible(true)}
          >
            Gérer le CRUD
          </Button>
          <TaskCRUDModale
            isModalVisible={isCRUDModalVisible}
            setIsModalVisible={setIsCRUDModalVisible}
            handleOk={() => setIsCRUDModalVisible(false)}
            handleCancel={() => setIsCRUDModalVisible(false)}
            sourceElements={sourceElements}
            operations={operations}
            outputs={outputs}
            setOperations={setOperations}
            setOutputs={setOutputs}
            collections={collections}
          />
        </Row>
      )}

      {typeOfTask === 'email' && (
        <>
          <Row className="drawer-action-row">
            <Col span={12}>
              <Title level={5} style={{ textAlign: 'center' }}>
                Inputs
              </Title>
              <Paragraph>{mapInputs(sourceElements)}</Paragraph>
            </Col>
            <Col span={12}>
              <Title level={5} style={{ textAlign: 'center' }}>
                Outputs
              </Title>
              <Paragraph>
                <Paragraph>{mapOutputs(outputs)}</Paragraph>
              </Paragraph>
            </Col>
          </Row>
          <Row>
            <Button
              type="primary"
              block
              onClick={() => setIsEmailModalVisible(true)}
              disabled={typeOfTask === ''}
            >
              Paramétrer l&apos;email
            </Button>
            <EmailModale
              isModalVisible={isEmailModalVisible}
              setIsModalVisible={setIsEmailModalVisible}
              handleOk={() => setIsEmailModalVisible(false)}
              handleCancel={() => setIsEmailModalVisible(false)}
              sourceElements={sourceElements}
              operations={operations}
              outputs={outputs}
              setOperations={setOperations}
              setOutputs={setOutputs}
            />
          </Row>
        </>
      )}
    </>
  );
};

WFLTaskForm.propTypes = {
  selectedElement: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    data: PropTypes.shape({
      label: PropTypes.string,
      taskType: PropTypes.string
    })
  }),
  elements: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  outputs: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  operations: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  setOutputs: PropTypes.func.isRequired,
  setOperations: PropTypes.func.isRequired,
  collections: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

WFLTaskForm.defaultProps = {
  selectedElement: {},
  elements: [],
  collections: []
};

export default WFLTaskForm;
