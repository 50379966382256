/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Steps } from 'antd';
import { ContentCustom } from '../../../../../components/ContentCustom/ContentCustom';
import CRUDCollectionSelection from './CRUDCollectionSelection';
import ETLStepFinish from '../../CommonSteps/StepFinish';
import StepTransform from '../../CommonSteps/StepTransform';
import { generateInitialFinalInputs } from '../../../../../utils/generateFinalInputs';

const TaskCRUDModale = ({
  isModalVisible,
  setIsModalVisible,
  handleOk,
  handleCancel,
  sourceElements,
  collections,
  operations,
  setOperations,
  setOutputs
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [stepInputs, setStepInputs] = useState(operations.stepInputs || []);
  const [finalInputs, setFinalInputs] = useState(
    (JSON.stringify(operations.finalInputs) !== '{}' &&
      operations.finalInputs) ||
      []
  );
  const [requestFilter, setRequestFilter] = useState(
    operations?.stepInputs?.requestFilter || ''
  );
  const [selectedCollection, setSelectedCollection] = useState(
    stepInputs.selectedCollection || ''
  );
  const [trigger, setTrigger] = useState(stepInputs.trigger || '');

  const onTriggerChange = (value) => {
    if (value === 'GET' || value === 'DELETE') setFinalInputs([]);
    setTrigger(value);
  };

  const validateStepSelection = () => {
    const collectionToMap = collections[selectedCollection];
    let newStepInputs = [];

    if (collectionToMap) {
      newStepInputs = Object.entries(collectionToMap).map((entries, index) => {
        const typeToString =
          typeof entries[1].type === 'string' ? entries[1].type : 'other';
        // TODO: Here is an instance of another shape of the data that needs to be normalized
        const value = {
          key: index,
          outputName: entries[0],
          formula: '',
          required: entries[1].required,
          outputType: typeToString
        };

        if (entries[1].enum !== undefined) {
          return {
            ...value,
            enum: entries[1].enum
          };
        }
        return value;
      });
    } else {
      newStepInputs = generateInitialFinalInputs('CRUD');
    }
    setStepInputs({ trigger, selectedCollection, requestFilter });
    setFinalInputs(newStepInputs);
    setCurrentStep(currentStep + 1);
  };

  const finishModale = () => {
    const newOutputs = {};
    finalInputs.map((operation) => {
      newOutputs[operation.outputName] = { type: operation.outputType };
    });

    setOperations({
      stepInputs: { trigger, selectedCollection, requestFilter },
      finalInputs
    });
    setOutputs(newOutputs);
    setIsModalVisible(false);
    handleOk();
  };

  const showStep2 = () => {
    if (trigger === 'POST' || trigger === 'PATCH') return true;
    return false;
  };

  const stepToShow = () => {
    switch (currentStep) {
      case 0:
        return (
          <CRUDCollectionSelection
            sourceElements={sourceElements}
            collections={collections}
            trigger={trigger}
            setTrigger={onTriggerChange}
            requestFilter={requestFilter}
            setRequestFilter={setRequestFilter}
            selectedCollection={selectedCollection}
            setSelectedCollection={setSelectedCollection}
            nextStep={validateStepSelection}
          />
        );
      case 1:
        if (!showStep2())
          return <ETLStepFinish description="Génération du CRUD terminé" />;
        return (
          <StepTransform
            trigger={trigger}
            isCRUD
            stepInputs={sourceElements}
            finalInputs={finalInputs}
            setFinalInputs={setFinalInputs}
            nextStep={() => setCurrentStep(currentStep + 1)}
          />
        );
      case 1 && !showStep2():
        return <ETLStepFinish description="Génération du CRUD terminé" />;
      case 2:
        return <ETLStepFinish description="Génération du CRUD terminé" />;
      default:
        return (
          <CRUDCollectionSelection
            sourceElements={sourceElements}
            collections={collections}
            trigger={trigger}
            setTrigger={setTrigger}
            requestFilter={requestFilter}
            setRequestFilter={setRequestFilter}
            selectedCollection={selectedCollection}
            setSelectedCollection={setSelectedCollection}
            nextStep={validateStepSelection}
          />
        );
    }
  };

  return (
    <Modal
      title="Création d'un CRUD"
      open={isModalVisible}
      maskClosable={false}
      width="90%"
      onOk={finishModale}
      onCancel={handleCancel}
    >
      <Steps
        current={currentStep}
        items={[
          {
            title: 'Selection de la collection à considérer',
            onClick: () => setCurrentStep(0)
          },
          showStep2() && {
            title: 'Intégration des inputs',
            onClick: () => setCurrentStep(1)
          },
          {
            title: 'Validation',
            onClick: () => setCurrentStep(2)
          }
        ]}
      />
      <ContentCustom>{stepToShow()}</ContentCustom>
    </Modal>
  );
};

TaskCRUDModale.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  sourceElements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  collections: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  operations: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  setOperations: PropTypes.func.isRequired,
  setOutputs: PropTypes.func.isRequired
};
export default TaskCRUDModale;
